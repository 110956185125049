
import CategoryBanners from '@/components/category_banners'
import CatalogCard from '../components/catalog_card.vue'
import { sortBy } from 'underscore'
import LoadingSpinner from '@/components/loading_spinner'

export default {
  components: {
    CategoryBanners,
    CatalogCard,
    LoadingSpinner
  }

  data: -> {
    categories: []
    products: []
    promotion_offers: []
    getting_banners: false
    getting_category: false
  }

  computed: {
    id: ->
      @$store.state.category.id

    name: ->
      @$store.state.category.name

    items: ->
      sortBy(@categories.concat(@products), 'name')

    offers: ->
      @$store.state.category_banners.offers

    loading: ->
      @getting_category || @getting_banners

    error: ->
      @$store.state.category.error
  }

  created: ->
    @$store.commit('layout/page_nav', { title: @name })
    @fetch_content()

  destroyed: ->
    @$store.commit('category/clear')
    @$store.commit('category_banners/clear')

  methods: {
    fetch_content: ->
      @getting_category = true
      @$store.dispatch('category/fetch', @$route.params)
      .then (category) =>
        @products = @with_navigation_targets(category.products, 'product')
        @categories = @with_navigation_targets(category.categories, 'category')
        @$store.commit('layout/page_nav', { title: @name })
      .finally =>
        @getting_category = false
        @set_page_title()
      @fetch_banners() if @id

    fetch_banners: ->
      @getting_banners = true
      @$store.dispatch('category_banners/fetch', {
        category_id: @id,
        supplier_id: @$store.state.suppliers.current_id
      })
      .finally =>
        @getting_banners = false

    set_page_title: ->
      document.title = @name
      @$store.dispatch('analytics/page_view')

    with_navigation_targets: (items, target) ->
      ((item.target = target; item) for item in items)
  }

  watch: {
    $route: ->
      @fetch_content()

    id: (id) ->
      @fetch_banners()

    error: (error) ->
      window.history.back() if !!error.length
  }
}
